module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-performance-metrics/gatsby-browser'),
      options: {"plugins":[],"firstPaint":true,"firstContentfulPaint":true,"firstInputDelay":true,"useLogging":true,"useGoogleAnalytics":false},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('/opt/buildhome/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
